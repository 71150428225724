<template>
  <div
    class="main"
    v-loading="loading"
    element-loading-text="生成中，请等待..."
  >
    <heads></heads>
    <banners></banners>
    <!-- <islogin /> -->
    <div class="content">
      <div class="cons">
        <div class="cons-title">单位历史发表期刊</div>
        <shuoming :smdata="smdata" :srcList="srcList"></shuoming>
        <div class="cons-title-subtitle">
          <p>
            按单位分：同事在发什么刊。按行业分：同行在发什么刊。按用途分：特定用途发什么刊
          </p>
        </div>
        <div class="cons-form">
          <el-form :model="form" label-width="120px">
            <div class="custom-block">填写机构</div>
            <el-form-item label="机构">
              <el-input
                v-model="form.writerorg"
                placeholder="请输入机构"
                @keyup.enter="onSubmit()"
              />
            </el-form-item>

            <div class="con-btn">
              <el-button type="primary" @click="onSubmit()">立即提交</el-button>
            </div>
          </el-form>
        </div>
        <div class="tables" v-if="tablelistxs.length > 0">
          <div class="custom-block tsys">
            <span>结果</span>
            <span class="tsys-span" @click="getmore">查看更多</span>
          </div>
          <div class="thfb-qikan">
            <div class="qksx-list-con xsqk-con">
              <div
                class="xsqk-list"
                v-for="(item, key) in tablelistxs"
                :key="key"
                @click="ckqkxq(item.journalId)"
              >
                <el-image
                  style="width: 98px; height: 128px; border: 1px solid #eee"
                  :title="item.journalName"
                  :src="proxy.$imgurl + '/qikanfmpic/' + item.pyCode + '.jpg'"
                ></el-image>
                <div class="xsqk-list-title">{{ item.journalName }}</div>
              </div>
            </div>
          </div>
          <!-- <el-table border :data="tablelist" style="width: 100%">
            <el-table-column prop="journalId" label="期刊名" />
            <el-table-column prop="totalTermFreq" sortable label="tf" />
          </el-table> -->
        </div>
      </div>
    </div>
    <footers></footers>
  </div>
</template>

<script setup>
import { post } from "@/utils/index";
import { ElMessage } from "element-plus";
import { reactive, ref, getCurrentInstance } from "vue";
import { useRouter } from "vue-router";

const router = useRouter();
const { proxy } = getCurrentInstance();
// 图片
let smdata = reactive([
  { url: "/img/shuoming/07-01.png", tips: "提交机构" },
  { url: "/img/shuoming/07-02.png", tips: "得到结果" },
]);
let srcList = reactive([
  "/img/shuoming/07-01.png",
  "/img/shuoming/07-02.png",
]);
// loading
let loading = ref(false);
let tablelist = reactive([]);
let tablelistxs = reactive([]);
let form = reactive({
  writerorg: "",
});

// 提交代码
const onSubmit = (formEl) => {
  loading.value = true;
  tablelist.length = 0;
  tablelistxs.length = 0
  // console.log(res);
  post("/journalOrganKeywords/getJournalByKeywords", {
    organ: form.writerorg,
    limit: 50,
  })
    .then((res) => {
      loading.value = false;
      // console.log(res)
      if (res.result.length > 0) {
        tablelist.push(...res.result);
        tablelist.forEach((e, i) => {
          if (i < 10) {
            tablelistxs.push(e);
          }
        });
      } else {
        ElMessage({
          type: "warning",
          message: "未找到相关期刊",
        });
      }
    })
    .catch((err) => {
      loading.value = false;
    });
};

// 显示更多
const getmore = () => {
  tablelistxs.length=0
  tablelistxs.push(...tablelist)
};

// 跳转期刊
const ckqkxq = (id) => {
  // const url = router.resolve({
  //   path: "/xkzs/del",
  //   query: {
  //     id: id,
  //   },
  // });
  // window.open(url.href);
  window.open('//journal.yuanpingjia.com.cn/Journal/Details/'+id)
};
</script>

<style scoped>
:deep(.el-form-item__label) {
  background: #f5f7fa;
  height: 42px;
  line-height: 42px;
  font-size: 18px;
}
:deep(.cons-form .el-input__inner) {
  height: 40px;
  font-size: 18px;
}
:deep(.cons-form .el-textarea__inner) {
  min-height: 100px !important;
  font-size: 18px;
}
:deep(.cons-form .el-button) {
  width: 180px;
  height: 50px;
  font-size: 18px;
}
.main {
  background: #fff;
}
.content {
  width: 1200px;
  height: auto;
  margin: auto;
}
.cons {
  width: 1200px;
  margin: auto;
  padding: 30px 0;
}
.cons-form {
  width: 100%;
}
.cons-title {
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  padding: 0 0 10px 0;
}
.cons-subtitle {
  width: 100%;
  padding: 10px 30px;
  box-sizing: border-box;
  background: #f5f7fa;
  font-size: 14px;
  line-height: 20px;
  color: #999;
  margin: auto;
  margin-bottom: 30px;
}
.custom-block {
  padding: 10px 16px;
  background-color: #ecf5ff;
  border-radius: 4px;
  border-left: 5px solid #409eff;
  margin: 20px 0 20px 0;
  font-size: 18px;
}
.con-btn {
  text-align: center;
}
.cons-title-subtitle {
  color: #999;
  font-size: 14px;
}
.cons-title-subtitle p {
  margin: 0;
  padding: 0;
  text-align: center;
}
.qksx-list-con {
  font-size: 14px;
  padding: 20px 0 0 0;
}
.qksx-list-con:after {
  content: "";
  display: table;
  clear: both;
}
/* .xsqk-con {
  display: flex;
  align-items: center;
  justify-content: space-between;
} */
.xsqk-list {
  float: left;
  cursor: pointer;
  margin: 0 10px 10px 10px;
}
.xsqk-list-title {
  width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: center;
  font-size: 12px;
}
.tsys {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.tsys-span {
  font-size: 14px;
  color: #409eff;
  font-weight: bold;
  cursor: pointer;
}
.tsys-span:hover {
  text-decoration: underline;
}
</style>
